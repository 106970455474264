import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/img/logo-u.jpeg"
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 wow fadeIn" data-wow-delay="0.1s" id="home">
                <Link to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
                    
                    <h1 className="m-0 text-primary"> <img src={logo} alt="Medisure Logo" style={{ height: '70px', width: 'auto' }}  className="logo-img" /></h1>
                </Link>
                <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <ScrollLink to="home" smooth={true} duration={400} className="nav-item nav-link ">Home</ScrollLink>
                        <ScrollLink to="about" smooth={true} duration={400} className="nav-item nav-link">About</ScrollLink>
                        <ScrollLink to="service" smooth={true} duration={400} className="nav-item nav-link">Service</ScrollLink>
                        {/* <div className="nav-item dropdown">
                            <ScrollLink to="#" smooth={true} duration={500} className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</ScrollLink>
                            <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                                <ScrollLink to="feature" smooth={true} duration={500} className="dropdown-item">Feature</ScrollLink>
                                <ScrollLink to="team" smooth={true} duration={500} className="dropdown-item">Our Doctor</ScrollLink>
                                <ScrollLink to="appointment" smooth={true} duration={500} className="dropdown-item">Appointment</ScrollLink>
                                <ScrollLink to="testimonial" smooth={true} duration={500} className="dropdown-item">Testimonial</ScrollLink>
                                <ScrollLink to="404" smooth={true} duration={500} className="dropdown-item">404 Page</ScrollLink>
                            </div>
                        </div> */}
                        {/* <ScrollLink to="team" smooth={true} duration={500} className="nav-item nav-link">Team</ScrollLink> */}
                    </div>
                    <ScrollLink to="contact"  smooth={true} duration={400}  className="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block">Contact Us<i className="fa fa-arrow-right ms-3"></i></ScrollLink>
                </div>
            </nav>
        </>
    );
};

export default Header;
