import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import AboutSection from "./sections/aboutSection";
import FeatureSection from "./sections/featureSection";
import ContactSection from "./sections/contactSection";
import MainSection from "./sections/mainSection";
import ServicesSection from "./sections/servicesSection";
import EducationSummitSection from "./sections/EducationSummitSection";
import { Link } from "react-router-dom";
export const HomePage = () => {
    return (
      <div>
        <Header />
        <MainSection />
        <EducationSummitSection/>
        <FeatureSection />
        <AboutSection />
        <ServicesSection/>
        <ContactSection/>
        <Footer />
        <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><Link 
  className="btn btn-outline-light btn-social rounded-circle" 
  to="https://wa.me/919972833411"  // Replace with actual phone number
  target="_blank"
>
  <i className="fab fa-whatsapp"></i>
</Link>
</a>

       </div>
    )
}