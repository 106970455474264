import React from 'react';


const FeatureSection = () => {

 
    return (
        <>
               <div className="container-fluid bg-primary overflow-hidden my-5 px-lg-0">
        <div className="container feature px-lg-0">
            <div className="row g-0 mx-lg-0">
                <div className="col-lg-12 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="p-lg-5 ps-lg-0">
                        <p className="d-inline-block border rounded-pill text-light py-1 px-4">Offer</p>
                        <h1 className="text-white mb-4">What the summit offers</h1>
                        <div className="row g-5">
                            <div className="col-lg-6 col-md-6">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light"  style={{ width: "55px", height: "55px" }}>
                                        <i className="fa fa-user-md text-primary"></i>
                                    </div>
                                            <div className="ms-4">
                                            <h5 className="text-white mb-0">Direct Access to Over 1,000 Students</h5>
                                        <p className="text-white mb-2">Engage with a wide range of students from different
                                        backgrounds, with the opportunity to conduct on-the-spot admissions.</p>
                                    
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light"  style={{ width: "55px", height: "55px" }}>
                                        <i className="fa fa-check text-primary"></i>
                                    </div>
                                            <div className="ms-4">
                                            <h5 className="text-white mb-0">Showcase Your University</h5>
                                        <p className="text-white mb-0">Present your university's achievements, academic
                                        programs, and scholarships directly to prospective students</p>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: "55px", height: "55px" }} >
                                        <i className="fa fa-comment-medical text-primary"></i>
                                    </div>
                                            <div className="ms-4">
                                            <h5 className="text-white mb-0">Inspiring Speakers</h5>
                                        <p className="text-white mb-2">Gain insights from leading voices from the Middle East and India,
                                        motivating students to explore international education opportunities</p>
                                    
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light"  style={{width:"55px", height:"55px"}}>
                                        <i className="fa fa-headphones text-primary"></i>
                                    </div>
                                            <div className="ms-4">
                                            <h5 className="text-white mb-0">Opportunity</h5>
                                        <p className="text-white mb-2">Don’t miss this unique opportunity to expand your university’s reach and connect
                                        with the next generation of global scholars</p>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-4 pe-lg-0 wow fadeIn" data-wow-delay="0.5s"  style={{minHeight:"400px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100" src="img/feature.jpg"  style={{objectFit:"cover"}}alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    </div>

      
            </>
    );
};

export default FeatureSection;
