import React from 'react';

const ContactSection = () => {
    return (
        <>
            <div className="container-xxl py-5" id="contact">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInUp text-center" data-wow-delay="0.1s">
                            <p className="d-inline-block border rounded-pill py-1 px-4">Contact Us</p>
                            <h1 className="mb-4">Contact Us to know more</h1>
                            
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="bg-light rounded d-flex align-items-center p-3 mb-4 h-100">
                                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"  style={{height:"55px",width:"55px"}}>
                                            <i className="fa fa-phone-alt text-primary"></i>
                                        </div>
                                        <div className="ms-4">
                                            <p className="mb-2">Call Us Now</p>
                                            <h6 className="mb-0"> +91 9972123423 / +91 9972833411</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 ">
                                    <div className="bg-light rounded d-flex align-items-center p-3 mb-4 h-100">
                                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"  style={{height:"55px",width:"55px"}}>
                                            <i className="fa fa-envelope-open text-primary"></i>
                                        </div>
                                        <div className="ms-4">
                                            <p className="mb-2">Mail Us Now</p>
                                            <h6 className="mb-0" >info@medisureinternational.com</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactSection;
