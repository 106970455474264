import React from 'react';
import { Link } from 'react-router-dom';

const ServicesSection = () => {

 
    return (
        <>
            <div className="container-xxl py-5" id="service">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s"  style={{maxWidth:"600px"}}>
                <p className="d-inline-block border rounded-pill py-1 px-4">Services</p>
                <h1>What We Do</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-light rounded h-100 p-5">
                        {/* <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"  style={{maxWidth:"65px",height:"65px"}}>
                            <i className="fa fa-heartbeat text-primary fs-4"></i>
                        </div> */}
                        <h4 className="mb-3">Comprehensive Healthcare Training</h4>
                        <p className="mb-4">Offering a wide range of specialized courses, including Basic Life Support (BLS), Advanced
Cardiovascular Life Support (ACLS), and Emergency Nursing Life Support (ENLS), to prepare
the next generation of healthcare professionals.</p>
                        <Link className="btn" href=""><i className="fa fa-plus text-primary me-3"></i>Read More</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item bg-light rounded h-100 p-5">
                        {/* <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{maxWidth:"65px",height:"65px"}}>
                            <i className="fa fa-x-ray text-primary fs-4"></i>
                        </div> */}
                        <h4 className="mb-3">Expert-Led Instruction</h4>
                        <p className="mb-4">Delivering top-tier training through American Heart Association (AHA) certified instructors,
ensuring students and professionals gain the critical skills needed for effective emergency
response</p>
                        <Link className="btn" href=""><i className="fa fa-plus text-primary me-3"></i>Read More</Link>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item bg-light rounded h-100 p-5">
                        {/* <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{maxWidth:"65px",height:"65px"}}>
                            <i className="fa fa-brain text-primary fs-4"></i>
                        </div> */}
                        <h4 className="mb-3">Student and University Engagement</h4>
                        <p className="mb-4">Facilitating direct interactions with over 1,000 students, providing universities with a
platform to showcase programs, conduct on-the-spot admissions, and connect with
educational consultants globally</p>
                        <Link className="btn" href=""><i className="fa fa-plus text-primary me-3"></i>Read More</Link>
                    </div>
                        </div>
                        

                        
            </div>
        </div>
    </div>

      
            </>
    );
};

export default ServicesSection;
