import React from "react";




export const ContactUs = () => {


    return (
      <div>
        COntact
      </div>
    )
}