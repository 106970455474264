import React from "react";




export const AboutPage = () => {


    return (
      <div>
        About US
      </div>
    )
}