import React from 'react';
import { Link } from 'react-router-dom';
const EducationSummitSection = () => {
    return (
        <>
            <div className="container-xxl py-5" id="education-summit">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                        <p className="d-inline-block border rounded-pill py-1 px-4">International Education Summit 2024</p>
                        <h1>October 2024 | Srinagar, J&K, India</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-12  col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="bg-light rounded h-100 p-5">
                                <h4 className="mb-3">Join Us at the International Education Summit 2024</h4>
                                <p className="mb-4">
                                    Held in the picturesque city of Srinagar, Jammu & Kashmir, India, this prestigious event will bring together over 30
                                    top-tier universities from around the world. The summit offers direct access to a diverse pool of students across various fields, including Medical, Non-Medical, Professional, and Management courses.
                                </p>
                                <h4 className="mb-3 mt-1" >Why Srinagar, J&K?</h4>
                                <p className="mb-4">
                                    Jammu and Kashmir is rapidly emerging as a key market for international universities in India. Recent research shows that over 100,000 students migrate annually to study at international universities, pursuing undergraduate and postgraduate degrees. By participating in this summit, your university can tap into this growing market.
                                </p>
                                <div className="d-flex justify-content-center">
                                <a
  className="btn btn-primary rounded-pill py-3 px-5 mt-3"
  href="https://wa.me/919972833411"  // Replace with actual phone number
  target="_blank"
  rel="noopener noreferrer"
>
  Register Now
</a>


</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EducationSummitSection;
