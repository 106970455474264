import React from 'react';
import { Link } from 'react-router-dom';
import carousel from "../../assets/img/img2.jpeg";
import carousel1 from "../../assets/img/img4.jpeg";
import carousel2 from "../../assets/img/slider3.jpeg";
import carousel3 from "../../assets/img/slider4.jpeg";

import OwlCarousel from 'react-owl-carousel';

const MainSection = () => {

   const option = {
      loop: true,             // Enables looping of items
    margin: 10,             // Margin between items
    nav: false,              // Show navigation arrows
    dots: true,             // Show pagination dots
    autoplay: true,         // Enable autoplay
    autoplayTimeout: 3000,  // Autoplay interval in milliseconds
    responsive: {
        0: {
            items: 1        // Show 1 item on screens from 0px up
        },
        600: {
            items: 1        // Show 1 item on screens from 600px up
        },
        1000: {
            items: 1        // Show 1 item on screens from 1000px up
        }
    }
  }
    return (
        <>
              <div className="container-fluid header bg-primary p-0 mb-5" >
        <div className="row g-0 align-items-center flex-column-reverse flex-lg-row">
            <div className="col-lg-5 p-5 wow fadeIn" data-wow-delay="0.1s">
                <h1 className="display-4 text-white mb-5">Empowering Excellence in Healthcare Education</h1>
                {/* <div className="row g-4">
                    <div className="col-sm-4">
                        <div className="border-start border-light ps-4">
                            <h2 className="text-white mb-1" data-toggle="counter-up">25000+</h2>
                            <p className="text-light mb-0">Students</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="border-start border-light ps-4">
                            <h2 className="text-white mb-1" data-toggle="counter-up">100+</h2>
                            <p className="text-light mb-0"> Paramedical</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="border-start border-light ps-4">
                            <h2 className="text-white mb-1" data-toggle="counter-up">100+</h2>
                            <p className="text-light mb-0">Courses</p>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
                <OwlCarousel className="owl-carousel header-carousel" {...option}>
                    <div className="owl-carousel-item position-relative">
                        <img className="img-fluid" src={carousel2} alt=""  />
                        {/* <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0">Cardiology</h1>
                        </div> */}
                    </div>
                    <div className="owl-carousel-item position-relative">
                        <img className="img-fluid" src={carousel3} alt="" />
                        {/* <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0">Neurology</h1>
                        </div> */}
                    </div>
                    <div className="owl-carousel-item position-relative">
                        <img className="img-fluid" src={carousel} alt=""  />
                        {/* <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0">Pulmonary</h1>
                        </div> */}
                            </div>
                            <div className="owl-carousel-item position-relative">
                        <img className="img-fluid" src={carousel1} alt="" />
                        {/* <div className="owl-carousel-text">
                            <h1 className="display-1 text-white mb-0">Pulmonary</h1>
                        </div> */}
                            </div>
                            
                </OwlCarousel>
            </div>
        </div>
    </div>
           

      
            </>
    );
};

export default MainSection;
