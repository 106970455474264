import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const Footer = () => {
    return (
        <>
             <div className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-6 col-md-6">
                    <h5 className="text-light mb-4">Contact Us</h5>
                    {/* <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p> */}
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+91 9972123423</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@medisureinternational.com</p>
                    <div className="d-flex pt-3">
                        <Link className="btn btn-outline-light btn-social rounded-circle" to="https://www.instagram.com/medisure_bls/" target='_blank'><i className="fab fa-instagram"></i></Link>
                        {/* <Link className="btn btn-outline-light btn-social rounded-circle" href=""><i className="fab fa-facebook-f"></i></ScrollLink>
                        <Link className="btn btn-outline-light btn-social rounded-circle" href=""><i className="fab fa-youtube"></i></ScrollLink>
                        <Link className="btn btn-outline-light btn-social rounded-circle" href=""><i className="fab fa-linkedin-in"></i></ScrollLink>
                     */}
                            </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <h5 className="text-light mb-4">Links</h5>
                    <ScrollLink className="btn btn-link" to="home" smooth={true} duration={400}>Home</ScrollLink>
                    <ScrollLink className="btn btn-link" to="about" smooth={true} duration={400}>About</ScrollLink>
                    <ScrollLink className="btn btn-link" to="service" smooth={true} duration={400}>Service</ScrollLink>
                    {/* <ScrollLink className="btn btn-link" to="team" smooth={true} duration={400}>Orthopedics</ScrollLink> */}
                    <ScrollLink className="btn btn-link" to="contact" smooth={true} duration={400}>Contact Us</ScrollLink>
                </div>
             
                {/* <div className="col-lg-3 col-md-6">
                    <h5 className="text-light mb-4">Newsletter</h5>
                    <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                    <div className="position-relative mx-auto" style={{maxWidth:"400px"}}>
                        <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                        <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div> */}
            </div>
        </div>
        {/* <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <Link className="border-bottom" href="#">Your Site Name</ScrollLink>, All Right Reserved.
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        Designed By <Link className="border-bottom" href="https://htmlcodex.com">HTML Codex</ScrollLink>
                        <br />
                        Distributed By <Link className="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</ScrollLink>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
        </>
    );
};

export default Footer;
