import React from 'react';
import { Link } from 'react-router-dom';
import carousel3 from "../../assets/img/slider2.jpeg";
import carousel2 from "../../assets/img/slider1.jpeg";

const AboutSection = () => {

 
    return (
        <><div className="container-xxl py-5" id="about">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="d-flex flex-column">
                        <img className="img-fluid rounded w-75 align-self-end" src={carousel3} alt="" />
                        <img className="img-fluid rounded w-50 bg-white pt-3 pe-3" src={carousel2} alt=""  style={{marginTop:"-25%"}} />
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <p className="d-inline-block border rounded-pill py-1 px-4">About Us</p>
                    <h1 className="mb-4">Welcome to Medisure Healthcare</h1>
                    <p> Your premier partner in healthcare education.
We are dedicated to shaping the future of healthcare by providing exceptional training for
nursing and medical students. At Medisure, we are committed to preparing tomorrow's
healthcare professionals with a comprehensive range of courses, including Basic Life Support
(BLS), Advanced Cardiovascular Life Support (ACLS), Emergency Nursing Life Support (ENLS),
Basic First Aid, and many other specialized medical and nursing training programs. Join us at
Medisure Healthcare, where excellence in training leads to excellence in care</p>
                   
                    {/* <Link className="btn btn-primary rounded-pill py-3 px-5 mt-3" to="">Read More</Link> */}
                </div>
            </div>
        </div>
    </div> 

      
            </>
    );
};

export default AboutSection;
